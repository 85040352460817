

.App-header {
  background-color: #222;
  padding: 20px;
  margin-bottom: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

